/******************************************************************************
						Home Page Slider
*******************************************************************************/
#homeslider-fullwidth {

	#homepage-slider {
		padding-left: 0;
		padding-right: 0;
		margin-bottom: 14px;
		width: 100%;
		max-width: 100%;
		max-height: 500px;
		overflow: hidden;
		position: relative;
		z-index: 1;
		float: left;
		box-shadow: 0 3px 10px grey;
		
		@media (max-width: 767px) {
			width: 100%;
			max-width: 100%;
			padding-top: 20px;		
		}
		
		.homeslider-description {    
			background: rgba(30, 69, 139, 0.5);
			padding: 20px;
			cursor: normal;
			position: absolute;
			left: 60%;
			top: 15px;
			width: 30%;
			
			h2 {
				font-size: 2.7em;
				color: white;
				margin: 0;
				padding: 0; 
				text-transform: uppercase;
			}
			
			p {
				width: 100%;
				font-size: 1.15em;
				line-height: 1.3em;
				padding: 0.5em 0;
				color: white; 
				
				@media (max-width: 768px) {
					font-size: 1em;
				}
				
				@media (max-width: 480px) {
					font-size: 0.8em; 
				}
			}
			
			a.btn {
				font-size: 1.2em;
				text-transform: uppercase; 
			}
			
			@media (max-width: 1200px) {
				padding: 20px;
				font-size: 0.8em; 
			}
			
			@media (max-width: 768px) {
				padding: 10px;
				font-size: 0.6em; 
			}
			
			@media (max-width: 480px) {
				padding: 5px;
				font-size: 0.5em; 
			}
		}
	}
}