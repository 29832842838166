/***********************************************************
					Stores Page Styles
************************************************************/

#stores {
	#map {
		width: 100%;
		height: 447px;
		margin-bottom: 26px;
	}
	input#addressInput {
		display: inline-block;
		width: 269px;
	}
	.address-input {
		float: left;
		margin-right: 19px;
		@media (max-width: $screen-xs-max) { // max 767px
			width: 100%;
			margin: 0 0 20px 0;
		}
		label {
			margin-right: 5px;
		}
	}
	.radius-input {
		float: left;
		line-height: 23px;
		margin-right: 20px;

		label,
		.selector {
			float: left;
		}

		label {
			margin-right: 10px;
		}

		#radiusSelect {
			width: 78px;
		}
	}
	.store-content {
		padding-bottom: 30px;
	}
	.store-content-select {
			.selector {
				visibility: hidden;
				display: none;
			}
			&.active {
				padding-top: 30px;
				margin-bottom: 30px;
				border-top: 1px solid $base-border-color;

			.selector {
				visibility: visible;
				display: block;
			}
		}
	}
	.store-title {
		margin-bottom: 16px;
	}
	label {
		font-weight: normal;
		color: $base-body-color;
	}
	#stores_loader {
	   display: none;
	   margin-left: 5px
	}
	#locationSelect {
   		max-width: 356px;
		visibility: hidden;
	}
	#stores-table {
		display: none;
		tbody {
			td {
				padding: 20px;
				&.num {
					background: $table-background;
					width: 46px;
				}
				&.name {
					span {
						padding-left: 15px;
					}
					@media (max-width: $screen-xs-max) { // max 767px
						img {display: none;}
					}
				}
			}
		}
	}
}

.store-image img {
	width: 100%;
	min-width: 100px;
}