@import 'bootstrap/_variables';

//------- FONTS
$font-family:				  		Arial, Helvetica, sans-serif;
$font:						  		normal 13px/18px $font-family;
$font-custom:                 		'Open Sans', sans-serif;
$font-icon:                   		"Font Awesome 5 Free";
$fa-css-prefix:       				icon !default;


//-------- BORDERS
$base-border-color:					#d6d4d4;
$main_border:						1px solid $base-border-color;
$dark-border-color:					#333;
$light-border-color:				#fff;
$homepage-footer-border:            #d9d9d9;

//--------- BACKGROUNDS
$base-box-bg:                       #fbfbfb;
$table-background:                  $base-box-bg;
$dark-background:                   #333;
$light-background:                  #fff;
$homepage-footer-bg:                #f2f2f2;

//--------- COLORS
$base-body-color:                   #777;
$base-text-color:                   #333;
$base-heading-color:				#555454;
$base-title-color:					$base-text-color;
$light-text-color:                  #fff;
$link-color:						$link-color;
$link-hover-color:                  $link-hover-color;
$description-color:					#666;


//--------- Indents
$base-page-indent:                 	$grid-gutter-width;
$base-box-padding:                  14px 18px 13px;
$base-box-indent:                  	0 0 30px 0;

//---------- Product Elements
$product-name-color:                #3a3939;
$product-name-hover-color:          #515151;
$price-color:						$base-body-color;
$product-price-color:				$base-text-color;
$product-reduced-price:				#f00;
$product-special-price-color:		#f13340;
$product-old-price-color:			#6f6f6f;

//---------- Buttons
$button-text-color:					$light-text-color;

		//------ Buttons +/- ---
		$button-pm-color:			#c0c0c0;
		$button-pm-hover-color:		$base-text-color;

		//------ Button Small --
		$button-small-bg:			#6f6f6f;
		$button-small-hover-bg:		#575757;

		//-------Button exclusive-medium
		$button-em-color:			$base-text-color;

//---------- Product Listing
$base-listing-border-color:					$base-border-color;
$base-listing-grid-padding:					$base-box-padding;

		//-------------- Grid/List vars
		$GL-text-color:								#808080;
		$GL-title-color:							$base-text-color;
		$GL-icon-color:								#e1e0e0;
		$GL-icon-color-hover:						$base-text-color;

		//-------------- Pagination vars
		$pagination-prevnext-color:					#777676;
		$pagination-bgcolor:	               		$base-box-bg;
		$pagination-hover-bg:						#f6f6f6;

//---------- Product Info
$top-line-color:									#C4C4C4;
$product-image-border:								#dbdbdb;
$scroll-button-color:								#c0c0c0;
$list-info-bg:										#f6f6f6;
$scroll-button-hover-color:							$base-text-color;
$tr-even-bg:										#fdfdfd;

//sub heading (h2,h3) define
@mixin sub-heading {
	color: $base-heading-color;
	text-transform: uppercase;
	font-family: $font-custom;
	font-weight: 600;
	font-size: 18px;
	line-height: 20px;
	padding: 14px 20px 17px;
	margin: 0 0 20px;
	position: relative;
	border: 1px solid $base-border-color;
	border-bottom: none;
	background: $base-box-bg;
}

$gray-darker:            lighten(#000, 13.5%) !default; // #222
$gray-dark:              lighten(#000, 20%) !default;   // #333
$gray:                   lighten(#000, 33.5%) !default; // #555
$gray-light:             lighten(#000, 60%) !default;   // #999
$gray-lighter:           lighten(#000, 93.5%) !default; // #eee

$body-bg:               #fff !default;
$text-color:            #777777 !default;

// Media queries breakpoints
// --------------------------------------------------

// Extra small screen / phone
$screen-xs:                  480px !default;
$screen-phone:               $screen-xs !default;

// Small screen / tablet
$screen-sm:                  768px !default;
$screen-tablet:              $screen-sm !default;

// Medium screen / desktop
$screen-md:                  992px !default;
$screen-desktop:             $screen-md !default;

// Large screen / wide desktop
$screen-lg:                  1200px !default;
$screen-lg-desktop:          $screen-lg !default;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max:              ($screen-sm - 1) !default;
$screen-sm-max:              ($screen-md - 1) !default;
$screen-md-max:              ($screen-lg - 1) !default;

$color-paris: #000525;
$color-montrouge: #3d4e93;

@mixin gradient-vertical-three-colors($start-color: #00b3ee, $mid-color: #7a43b6, $color-stop: 50%, $end-color: #c3325f) {
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from($start-color), color-stop($color-stop, $mid-color), to($end-color));
  background-image: -webkit-linear-gradient($start-color, $mid-color $color-stop, $end-color);
  background-image: -moz-linear-gradient(top, $start-color, $mid-color $color-stop, $end-color);
  background-image: linear-gradient($start-color, $mid-color $color-stop, $end-color);
  background-repeat: no-repeat;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{ie-hex-str($start-color)}', endColorstr='#{ie-hex-str($end-color)}', GradientType=0); // IE9 and down, gets no color-stop at all for proper fallback
}

@mixin gradient-vertical($start-color: #555, $end-color: #333, $start-percent: 0%, $end-percent: 100%) {
  background-image: -webkit-gradient(linear, left $start-percent, left $end-percent, from($start-color), to($end-color)); // Safari 4+, Chrome 2+
  background-image: -webkit-linear-gradient(top, $start-color, $start-percent, $end-color, $end-percent); // Safari 5.1+, Chrome 10+
  background-image:  -moz-linear-gradient(top, $start-color $start-percent, $end-color $end-percent); // FF 3.6+
  background-image: linear-gradient(to bottom, $start-color $start-percent, $end-color $end-percent); // Standard, IE10
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{ie-hex-str($start-color)}', endColorstr='#{ie-hex-str($end-color)}', GradientType=0); // IE9 and down
}

@mixin fa($icon) {
	font-family: 'Font Awesome 5 Free';
	font-weight: 900;
	content: $icon;
}

@mixin far($icon) {
	font-family: 'Font Awesome 5 Free';
	font-weight: 400;
	content: $icon;
}