body.theme-montrouge {
  .header-container {
    background: url(../img/header-bg.png) no-repeat !important;
    background-size: cover !important;
  }
  #cmsinfo_block .hublot > div,
  ul.product_list.grid .ajax_block_product .product-container .left-block .product-image-container::before,
  .bottom-pagination-content #pagination_bottom .pagination #pagination_previous_bottom::after,
  .bottom-pagination-content #pagination_bottom .pagination #pagination_next_bottom::before,
  h3.page-product-heading, .page-product-heading.h3,
  .accessories-block .item,
  #crossselling_list_car .item,
  .preheader-container ul.shops li .submenu {
    background: $color-montrouge;
  }
  ul.product_list.grid .ajax_block_product .product-container {
    background: $color-montrouge !important;
  }
  .page-heading,
  #header #search_block_left .content-input-button #search_button i,
  .content_sortPagiBar .display li.display-title, .content_sortPagiBar .display_m li.display-title,
  .content_sortPagiBar .display li#grid a::before, .content_sortPagiBar .display_m li#grid a::before,
  .content_sortPagiBar .display li#list a::before, .content_sortPagiBar .display_m li#list a::before,
  .bottom-pagination-content #pagination_bottom .pagination li a, .bottom-pagination-content #pagination_bottom .pagination li span,
  .bottom-pagination-content #pagination_bottom .pagination #pagination_previous_bottom span::before, .bottom-pagination-content #pagination_bottom .pagination #pagination_previous_bottom a::before,
  .bottom-pagination-content #pagination_bottom .pagination #pagination_next_bottom span::before, .bottom-pagination-content #pagination_bottom .pagination #pagination_next_bottom a::before,
  .pb-center-column h1, .pb-center-column .h1 {
    color: $color-montrouge;
  }
  .content_sortPagiBar .sortPagiBar #productsSortForm select,
  .content_sortPagiBar .sortPagiBar #productsSortForm #categories_block_left .categories-list {
    border-color: $color-montrouge;
  }
  .box-info-product .exclusive,
  .button.standard-checkout,
  .button.exclusive,
  #SubmitLogin {
    @extend .btn-montrouge;
  }

  ul.product_list.grid .ajax_block_product .product-container .right-block .button.ajax_add_to_cart_button span {
    @extend .btn-montrouge-inversed;
  }
  &.cms-galerie-poissonnerie #center_column .rte img,
  &.cms-publications-poissonnerie #center_column .rte img,
  &.cms-galerie-seabar #center_column .rte img {
    border-color: $color-montrouge;
  }
  &.cms-le-menu {
    .rte {
      h2 {
        color: $color-montrouge;
      }
      h3 {
        color: $color-montrouge;
        &.menu-price {
          font-size: 1.5rem !important;
        }
        span.normal-color {
          color: #777;
        }
      }
      h4 {
        color: $color-montrouge;
        font-size: 1.2rem;
      }
      .color-montrouge {
        color: $color-montrouge;
      }
    }
  }
}