/*******************************************************
				Addresses Styles
********************************************************/
#addresses {
	#center_column {
		.page-heading {
			margin: 0 0 22px;
		}
		p {
			margin: 0 0 13px;

			&.p-indent {
				margin: 0 0 27px;
			}
		}
	}
}

.address {
	li {
		&.address_update {
			margin: 14px 0 6px 0;
			a {
				margin: 0 10px 0 0;
			}
		}
	}
}