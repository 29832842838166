#my-account {
	.addresses-lists {
		margin-bottom: 30px;
	}
	ul.myaccount-link-list {
		li {
			overflow: hidden;
			padding-bottom: 10px;
			a {
				display: block;
				overflow: hidden;
				font: 600 16px/20px $font-custom;
				color: #555454;
				text-shadow: 0px 1px rgba(255,255,255,1);
				text-transform: uppercase;
				text-decoration: none;
				position: relative;
				border: 1px solid;
				border-color: #cacaca #b7b7b7 #9a9a9a #b7b7b7;
				// @include background-image(linear-gradient(rgb(247,247,247), rgb(237,237,237)));
				border-radius: 4px;
				i {
					font-size: 25px;
					color: #fd7e01;
					position: absolute;
					left: 0;
					top: 0;
					width: 52px;
					height: 100%;
					padding: 10px 0 0 0;
					text-align: center;
					border: 1px solid $light-border-color;
					border-top-left-radius: 4px;
					border-bottom-left-radius: 4px;
				}
				span {
					display: block;
					padding: 13px 15px 15px 17px;
					overflow: hidden;
					border: 1px solid;
					margin-left: 52px;
					border-color: $light-border-color $light-border-color $light-border-color #c8c8c8;
					border-top-right-radius: 5px;
					border-bottom-right-radius: 5px;
				}
				&:hover {
					filter: none;
					background: #e7e7e7;
					border-color: #9e9e9e #c2c2c2 #c8c8c8 #c2c2c2;
				}
			}
		}
	}
}