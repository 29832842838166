/**********************************************************
				Authentication Styles
**********************************************************/
#account-creation_form .id_state,
#account-creation_form .dni,
#account-creation_form .postcode {
	display: none;
}

#create-account_form {
	min-height: 297px;
	p {
		margin-bottom: 8px;
	}
	.form-group {
		margin: 0 0 20px 0;
	}
}

#login_form {
	min-height: 297px;
	.form-group {
		margin: 0 0 3px 0;
		&.lost_password {
			margin: 14px 0 15px 0;
			a {
				text-decoration: underline;
				&:hover {
					text-decoration: none;
				}
			}
		}
	}
}

#login_form, #create-account_form {
	.form-control {
		max-width: 271px;
	}
}