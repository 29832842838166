/******************************************************************
					Sitemap Page Styles
*******************************************************************/
#sitemap {
	.sitemap_block {
		.page-subheading {
			margin-bottom: 16px;
		}
		li {
			line-height: 16px;
			padding-bottom: 11px;
			a {
				&:before {
					content: "\f105";
					display: inline-block;
					font-family: $font-icon;
					padding-right: 10px;
				}
				&:hover {
					font-weight: bold;
				}
			}
		}
	}
}

#listpage_content div.tree_top {
	padding: 5px 0 0 27px;
	a {
		&:before {
			content: "\f015";
			display: inline-block;
			font-family: $font-icon;
			font-size: 20px;
			color: $base-text-color;
		}
		&:hover {
			&:before {
				color: $link-hover-color;
			}
		}
	}
}

ul.tree {
	padding-left: 24px;
	li {
		margin: 0 0 0 21px;
		padding: 5px 0 0 33px;
		border-left: 1px solid $base-border-color;
		background: url(../img/sitemap-horizontal.png) no-repeat left 15px transparent;
		a:hover {
			font-weight: bold;
		}
	}
	> li {
		margin: 0 0 0 11px;
	}
	li.last {
		border: medium none;
		background: url(../img/sitemap-last.png) no-repeat 0px -4px transparent;
	}
}