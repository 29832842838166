body {
	background: #282828;
	background-size: 100%;
	font-family: 'PT Sans', 'sans-serif';
	line-height: 1.4em;

	.columns-container, .footer-container {
		.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12 {
			padding-left: 15px;
			padding-right: 15px;
		}
	}

	a {
		text-decoration: none;
		color: #354478;
		transition: 0.4s;
		&:hover {
			text-decoration: none;
		}
	}

	i {
    font-style: italic;
  }

	/* centered columns styles */
	.row-centered {
		text-align:center;
	}
	.col-centered {
		display:inline-block;
		float:none;
		/* reset the text-align */
		text-align:left;
		/* inline-block space fix */
		margin-right:-4px;
	}
}

.block {
	margin-bottom: 60px;
}

h1, h2, h3, h4, h5 {
	font-family: 'PT Sans' !important;
}

.preheader-container {
	ul.shops {
		display: flex;
		padding: 0;
		li {
			list-style: none;
			padding: 10px 15px;
			@include media-breakpoint-down(sm) {
				padding: 10px 8px;
			}

			a {
				color: $white;
				border-bottom: 1px solid $white;
				font-size: 1rem;
				@include media-breakpoint-down(sm) {
					font-size: 0.8rem;
				}

				&:hover {
					color: black;
					border-color: black;
				}
			}

			&:first-child {
				padding-left: 0;
			}
			.submenu {
				display: none;
				position: absolute;
				background: $color-paris;
				z-index: 50000;
				padding: 10px;
				min-width: 150px;
				max-width: 100%;
				box-shadow: 0px 6px 20px 0px #00000059;
				li {
					padding: 0;
					margin-bottom: 1.2em;
					&:last-child {
						margin-bottom: 0;
					}
				}
			}
			&:hover {
				.submenu {
					display: block;
				}
			}
			// &.with-submenu {
			// 	&::after {
			// 		position: relative;
			// 		@include fa('\f0d7');
			// 		margin-left: 5px;
			// 		color: white;
			// 	}
			// }
		}
	}
	
	.content-header {
		& > div.nav {
			margin-left: auto;
			.header_user_info {
				a {
					color: $light-text-color;
					font-weight: bold;
					display: block;
					padding: 8px 9px 11px 8px;
					cursor: pointer;
					line-height:18px;
					font-size: 1rem;
					font-weight: 100;
					padding-top: 10px;
					@media (max-width: $screen-xs - 1) {
						font-size: 11px;
					}
					&:hover, &.active {
						color: black;
					}
					i {
						font-size: 1.3rem;
					}
				}
			}
		}
	}

	.iqitmegamenu-wrapper {
		width: 100%;
		z-index: 6000;
		padding-top: 0;
		#cbp-hrmenu {
			& > ul {
				display: flex;
				justify-content: space-between;
				.cbp-tab-title {
					font-family: 'Montserrat', sans-serif;
					font-size: 1rem;
					font-weight: 500;
					text-align: center;
				}
				a {
					font-size: 1rem;
				}
			}
		}
	}
}

#header {
	background: none;
	.shopping_cart {
		position: relative;
		float: right;
		margin-top: -30px;
		padding: 0;

		@media (max-width: 767px) {
			margin-top: 30px;
		}
	}
	#search_block_top {
		padding-top: 30px;
	}
	.cart_block {
		top: 45px;
	}
	#multistoreswitcher_trigger {
		margin-top: -80px;
	}
	#search_block_left {
		text-align: center;
		.content-input-button {
			width: 250px;
			display: inline-block;
			position: relative;
			#search_query_block {
				height: 35px;
				border-radius: 0 !important;
				border: 0;
				margin: 0;
			}
			#search_button {
				position: absolute;
				right: 0;
				top: 50%;
				transform: translateY(-50%);
				background: transparent !important;
				border: 0;
				box-shadow: none;
				& > span {
					border: 0;
					background: transparent !important;
					&:hover {
						i {
							color: black;
						}
					}
				}
				i {
					color: $color-paris;
					font-size: 1.5rem;
					margin-bottom: 2px;
				}
			}
		}
	}
}

#postheader-container {
	background: url(../img/back-title.jpg) no-repeat center top #f1ead9;
	border-bottom: 5px solid #d5bf8d;
	height: 120px;
	h1 {
		padding: 20px;
		background: rgba(255, 255, 255, 0.5);
		text-transform: uppercase;
		min-width: 40%;
		float: left;
		color: black;
	}
}

.columns-container {
	background: #f1ead9;

	.breadcrumb {
		background: transparent;
		border: none;
	}
}

#header {
	.row #header_logo {
		margin: auto;
		width: 100%;
		text-align: center;
		padding-top: 0;
	}
	#search_block_top #search_query_top {
		border-color: #d6d4d4;
		background: rgba(255, 255, 255, 0.8);
		color: #686666;
	}
}

#header_logo {
	img {
		margin: auto;
		position: relative;
		margin-top: 2em;
		width: 360px;
		max-width: 100%;
		height: auto;
	}
	.text-logo {
		color: white;
		margin-bottom: 7em;
		text-transform: uppercase;
		font-size: 1.1rem;
		letter-spacing: 2px;
		margin-top: 10px;
		font-weight: 600;
		@include media-breakpoint-down(sm) {
			margin-bottom: 1em;
		}
	}
}

#center_column {
	.product-name {
		font-size: 16px;
	}
	.content_sortPagiBar {
		.bottom-pagination-content {
			form.showall,
			.product-count {
				display:none;
			}
		}
	}
}

.columns-container {
	background: white !important;
}

#cmsinfo_block {
	background: transparent !important;
	border: 0 !important;
	margin-top: 3em;
	.hublot {
		border: 0;
		margin-bottom: 3em;
		& > div {
			border-top-right-radius: 50px;
			border-bottom-left-radius: 50px;
			background: $color-paris;
			height: 100%;
			@include media-breakpoint-up(lg) {
				margin: 0 7em;
			}
			.img {
				position: relative;
				font-size: 0;
				border-top-right-radius: 50px;
				border-bottom-left-radius: 50px;
				height: 300px;
				width: 100%;
				z-index: 5;
				a {
					width: 100%;
					height: 100%;
					display: inline-block;
					z-index: 50;
					position: relative;
					&::before {
						position: absolute;
						content: " ";
						width: 100%;
						height: 100%;
						z-index: 5;
						border-top-right-radius: 50px;
						border-bottom-left-radius: 50px;
					}
					&.plateaux-composes:before {
						background: url(../img/hublot/le-royal-grands-crus.jpg) no-repeat center center transparent;
						background-size: 105%;
					}
					&.plateaux-carte:before {
						background: url(../img/hublot/bourriche.jpg) no-repeat center center transparent;
						background-size: 115%;
					}
					&.accompagnements:before {
						background: url(../img/hublot/produits-traiteur-accompagnements.jpg) no-repeat center center transparent;
						background-size: 100%;
					}
					&.vins-champagnes:before {
						background: url(../img/hublot/haut-pougnan-entre-deux-mers-blanc.jpg) no-repeat center center transparent;
						background-size: 120%;
					}
					&.plats-cuisines:before {
						background: url(../img/hublot/plats-cuisines.jpg) no-repeat center center transparent;
						background-size: 105%;
					}
				}
				&::after {
					position: absolute;
					content: " ";
					background: white;
					width: calc(100% + 5px);
					height: 100%;
					bottom: -10px;
					left: -5px;
					z-index: 2;
					border-top-right-radius: 50px;
					border-bottom-left-radius: 50px;
				}
			}
			h1 {
				padding: 1.2em 5em 1em 5em;
				text-align: center;
				font-size: 1.2rem;
				font-weight: normal;
				display: flex;
				a {
					margin: auto;
					color: white;
					font-weight: normal;
					font-family: 'Montserrat', sans-serif;
				}
			}
		}
	}
}

#editorial_block_center {
	display: none;
	margin: 3em 0 0;
	background: #FFFFFF;
	padding: 20px 20px 45px;
	box-shadow: 0px 0px 15px #ccc inset;
	h1 {
		margin: 0;
		padding: 0;
		background: none;
	}
	h2 {
		padding: 0 0 10px 0;
		font-size: 2em;
		line-height: 1.2em;
		color: #666;
		text-transform: none;
		background: none;
	}
}

.cms-nos-fournisseurs #center_column .rte h2 {
	font-size: 17px;
}

.contact-form-box {
	background: white;
}

.footer-container {
	background: #282828;

	#footer.container {
		padding: 25px 15px;
		a {
			color: white;
		}
		p {
			font-size: 15px;
			line-height: 22px;
			color: whitesmoke;
		}
		h2 {
			font-size: 1.2rem;
			font-weight: 600;
			text-align: left;
			text-transform: uppercase;
			padding-bottom: 10px;
			margin-bottom: 0;
			margin-top: 0;
			color: white;
			font-family: 'PT Sans', 'sans-serif';
		}

		ul.social {
			padding: 0;
			margin-top: 1em;
			li {
				list-style: none;
				display: inline-block;
				margin: 0 13px;
				font-size: 20px;
				color: white;
				text-align: center;
				a {
					font-size: 2.3rem;
					display: block;
					&:hover {
						color: $color-paris;
					}
				}
			}
		}

		#block_various_links_footer {
			text-align: center;
			a {font-weight: normal;}
			ul {
				li {
					float: left;
					margin-right: 20px;
				}
			}
			#serval {
				clear: both;
			}
		}
	}
}

.bottom-pagination-content {
	#pagination_bottom {
		.pagination {
			margin: auto;
			float: none;
			li {
				a, span {
					background: transparent !important;
					border: 0 !important;
					color: $color-paris;
					font-size: 1rem;
				}
				&.active,
				&.current {
					a, span {
						color: black;
					}
				}
			}
			#pagination_previous_bottom {
				span, 
				a {
					display: inline-block;
					b {
						font-size: 0;
					}
				}
				&::after {
					content: "";
					position: relative;
					width: 20px;
					height: 1px;
					display: inline-block;
					background-color: $color-paris;
					vertical-align: middle;
    			margin-bottom: 5px;
					margin-left: 1em;
				}
			}
			#pagination_next_bottom {
				span, 
				a {
					display: inline-block;
					b {
						font-size: 0;
					}
				}
				&::before {
					content: "";
					position: relative;
					width: 20px;
					height: 1px;
					display: inline-block;
					background-color: $color-paris;
					vertical-align: middle;
    			margin-bottom: 5px;
					margin-right: 1em;
				}
			}
		}
	}
}

.cms-galerie-poissonnerie #center_column .rte,
.cms-publications-poissonnerie #center_column .rte {
	& > p {
		@extend .row;
		.fancybox {
			@extend .col-12;
			@extend .col-md-2;
			img {
				border: 5px solid $color-paris;
			}
		}
	}
}

.cms-galerie-seabar #center_column .rte {
	img {
		width: 150px;
    height: auto;
    margin: 10px !important;
    border: 5px solid $color-paris;
    cursor: pointer;
	}
}

#cmsinfo_block-paris,
#cmsinfo_block-montrouge {
	.hublot {
		@include media-breakpoint-up(md) {
			padding: 2em;
		}
		@include media-breakpoint-down(sm) {
			margin-bottom: 2em !important;
		}
		& > div {
			border-top-right-radius: 50px;
			border-bottom-left-radius: 50px;
			background: $color-paris;
			height: 100%;
			.img {
				position: relative;
				font-size: 0;
				border-top-right-radius: 50px;
				border-bottom-left-radius: 50px;
				height: 250px;
				width: 100%;
				z-index: 5;
				a {
					width: 100%;
					height: 100%;
					display: inline-block;
					z-index: 50;
					position: relative;
				}
				&::before {
					position: absolute;
					background-size: cover;
					content: " ";
					background: url(../img/hublot/le-royal-grands-crus.jpg) no-repeat center center transparent;
					-webkit-background-size: 105% 105%;
					background-size: 105%;
					width: 100%;
					height: 100%;
					z-index: 5;
					border-top-right-radius: 50px;
					border-bottom-left-radius: 50px;
				}
				&::after {
					position: absolute;
					content: " ";
					background: white;
					width: calc(100% + 5px);
					height: 100%;
					bottom: -10px;
					left: -5px;
					z-index: 2;
					border-top-right-radius: 50px;
					border-bottom-left-radius: 50px;
				}
			}
			.link {
				padding: 1.2em 3em 1em 3em;
				text-align: center;
				font-size: 1.1rem;
				font-weight: normal;
				h1 {
					font-size: 2rem;
					a {
						font-weight: 600;
					}
				}
				a {
					color: white;
					font-weight: normal;
				}
				& > a {
					font-size: 1.4rem;
				}
			}
		}
		&:last-child {
			& > div {
				.img::before {
					background-image: url('../img/hublot/resto-ardoise.jpg');
				}
			}
		}
	}
	&.plateaux-fruits-mer {
		h2 {
			font-size: 1.6rem;
		}
		.hublot {
			div .link > a {
				font-size: 1.3rem;
			}
			&:last-child {
				& > div {
					background: $color-montrouge;
					.img::before {
						background-image: url('../img/hublot/le-royal-grands-crus.jpg');
					}
				}
			}
		}
	}
}

#cmsinfo_block-montrouge {
	.hublot {
		&:first-child {
			& > div {
				background: $color-montrouge;
				.img::before {
					background-image: url('../img/hublot/resto-ardoise.jpg');
				}
			}
		}
		&:last-child {
			& > div {
				background: $color-montrouge;
				.img::before {
					background-image: url('../img/hublot/le-royal-grands-crus.jpg');
				}
			}
		}
	}
	&.plateaux-fruits-mer {
		h2 {
			font-size: 1.6rem;
		}
		.hublot {
			div .link > a {
				font-size: 1.3rem;
			}
			&:first-child {
				& > div {
					background: $color-montrouge;
					.img::before {
						background-image: url('../img/hublot/le-royal-grands-crus.jpg');
					}
				}
			}
		}
	}
}

#multistoreswitcher_dialog {
	#uniform-multistoreswitcher_select {
		width: 100% !important;
		.form-control {
			height: auto !important;
		}
	}
}

.ui-dialog-titlebar-close {
	font-size: 0 !important;
	padding: 0 !important;
	&::before {
		@include fa('\f00d');
		font-size: 1rem;
	}
}